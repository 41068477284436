
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.post-btn-group {
  opacity: 0 !important;  /* default opacity when is_detail is false */
}

.post-btn-group-hover {
  transition: opacity 1s ease-in-out;
  opacity: 1 !important;  /* opacity on hover when is_detail is false */
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.75s forwards;
}

.fade-out {
  animation: fadeOut 0.75s forwards;
}
/* Container to hold the columns */
#column-container {
  display: flex;
  justify-content: space-between;
}

/* Individual column styles */
.custom-column {
  width: 33.33%;
  padding: 15px;
}

/* Column item styles */
.column-item {
  margin-bottom: 16px;
}

/* Media query for responsiveness */
@media (max-width: 992px) {
  .custom-column {
    width: 100%;
  }
}


.fade-transition {
  transition: opacity 0.5s;
}

.video-container {
  position: relative;
}

.thumbnail, .react-video-player-native {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px; /* Apply the border-radius */
}

.thumbnail {
  object-fit: cover;
  width: 100%;
}

.video-container .thumbnail img {
  border-radius: 10px !important;
}


.thumbnail.hidden {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.react-video-player-native {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.react-video-player-native video { /* Directly target the video element */
  border-radius: 10px !important; /* This will ensure the actual video has rounded corners */
}

.video-container .react-video-player-native {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition-delay: 1s; /* Add this line */
}

.video-container:hover .react-video-player-native {
  opacity: 1;
  transition-delay: .4s; /* Ensure there's no delay when hovering out */
}


.fade-transition {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.fade-transition.fade-in {
  opacity: 1;
}






.custom-link {
  color: var(--main-font-color) !important;
  text-decoration: none; /* This removes the underline */
  transition: text-decoration 0.3s ease; /* This makes the underline appear smoothly */
}

.custom-link:hover {
  color: var(--main-font-color) !important;
  text-decoration: underline; /* This adds the underline on hover */
}

.spinner-border {
  opacity: 0; /* Default to hidden */
  transition: opacity 1s ease-in-out; /* This applies the fade effect */
}
.spinner-border-show {
  opacity: 1; /* Default to hidden */
  transition: opacity 1s ease-in-out; /* This applies the fade effect */
}

<style>

.video-container .react-video-player-native {
  opacity: 0;
  transition: opacity 0.4s ease-in 0s; /* 1s delay */
}
.video-container:hover .react-video-player-native {
  opacity: 1;
  transition: opacity 0.4s ease-in .4s; /* No delay */
}

.fade-transition {
  opacity: 0;
}

.fade-transition.fade-in {
  opacity: 1;
  transition-delay: .4s; /* Delay only on hover-in */

}





@media (max-width: 992px) {
        .custom-column:nth-of-type(2) {
        /* styles for the second element with .example-class */
        display:none !important;
    }

    .custom-column:nth-of-type(3) {
        /* styles for the third element with .example-class */
        display:none !important;

    }
}

#nprogress .bar {
  background: rgba(0, 102, 255, 0.8) !important;

}




#post-feed{
  overflow:hidden;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}


.fade-out {
  transition: opacity .5s ease-in-out;
  opacity: 0;
}


#company-link:hover {
    text-decoration: underline !important;
}
#hover-id:hover {
    text-decoration: underline !important;
}

div:hover img.expand-on-hover {
    top: -50%;  
    left: -50%;  
    width: 200%;  
    height: 200%; 
    overflow: auto;
}
@media(min-width:1800px){
  .container{
    max-width: 100% !important;
    padding-right:10% !important;
    padding-left:10% !important;
  }
}

@media(max-width:1800px){
  .container{
    max-width: 100% !important;
    padding-right:4% !important;
    padding-left:2.5% !important;
  }
}
@media (max-width: 1666px) {
    .content-continer {
        padding-left: 60px;
    }
}

@media (max-width: 1200px) {
    .post-timestamp-display{
        display:none !important;
    }
}


.post-btn-group {
  opacity: 0 !important;  /* default opacity when is_detail is false */
}

.post-btn-group-hover {
    display:none;
  transition: opacity 1s ease-in-out;
  opacity: 0 !important;  /* opacity on hover when is_detail is false */
}







/* Container to hold the columns */
#column-container {
  display: flex;
  justify-content: space-between;
}

/* Individual column styles */
.custom-column {
  width: 33.33%;
  padding: 20px;
  padding-top: 5px;
}

/* Column item styles */
.column-item {
  margin-bottom: 5px;
}

/* Media query for responsiveness */
@media (max-width: 992px) {
  .custom-column {
    width: 100%;
  }
}

.px-2{
  padding-left: 0rem !important;
  padding-right: 0rem !important; 
}












.fade-transition {
  transition: opacity 0.5s;
}
.fade-transition:hover{
  opacity: .1;
}


.video-container {
  position: relative;
}

.thumbnail, .react-video-player-native {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px; /* Apply the border-radius */
}
.thumbnail{
  border: 1px solid transparent !important;
  object-fit: cover;
  width: 100%;
}

.video-container .thumbnail img {
  border-radius: 10px !important;
}


.thumbnail.hidden {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.react-video-player-native {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.react-video-player-native video { /* Directly target the video element */
  border-radius: 10px !important; /* This will ensure the actual video has rounded corners */
}

.video-container:hover .react-video-player-native {
  opacity: 1;
}







.custom-link {
  color: var(--main-font-color) !important;
  text-decoration: none; /* This removes the underline */
  transition: text-decoration 0.3s ease; /* This makes the underline appear smoothly */
}

.custom-link:hover {
  color: var(--main-font-color) !important;
  text-decoration: underline; /* This adds the underline on hover */
}

html {
  scroll-behavior: smooth;
}



.video-container{
    overflow: hidden !important;
    border-radius: 10px !important;
    margin-bottom: 10px !important;

}

.spinner-border {
  opacity: 0; /* Default to hidden */
  transition: opacity 1s ease-in-out; /* This applies the fade effect */
}
.spinner-border-show {
  opacity: 1; /* Default to hidden */
  transition: opacity 3s ease-in-out; /* This applies the fade effect */
}










.react-video-player{
  width: auto !important;

}

.react-video-player-native video {
  object-fit: cover !important;
  object-position: center !important;
  width: 100% !important;
}
video{
  border-radius: 10px !important;
}

.giga-profile-badge{
  padding-top:29px !important;
}
.action-btn{
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.comment-btn{
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.repost-btn{
  margin-left: 30px !important;
}
@media(max-width:1200px){
      #author-top{
          display: block !important;
          margin-left: 15px !important;
          padding-right: 4.5% !important;
      }

      #recommended-post-container{
          display: none !important;
      }
  }
@media(max-width:992px){
      #author-top{
          margin-left: 15px !important;
          padding-right: 6% !important;
      }
}
@media(min-width:1500px){
      #username-display{
          font-size: 20px !important;
      }
  }
#creat-comment-input{
  margin-top: 7px !important;
}
@media (min-width: 1500px) {
  #recommended-post-container{
      padding-right:35px !important;
  }
}


.fa-circle-play{
  display: none;
}
.fa-camera{
  display: none !important;
}
.icon-container{
  z-index: 10000 !important;
}
.expand-on-hover {
  max-height: 1000px !important;

}
@media (max-width: 1500px) {
  .react-video-player{
      max-height: 550px !important;

  }
}
.content-background{
  border: 1px solid rgba(92,92,92,.5);
  background-color: rgba(92,92,92,.2) !important;
  overflow:hidden !important;
}






video {
  border-radius: 10px !important;
  overflow: hidden;
}





.scrolling-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.search-term {
  text-decoration: none !important;
  display: inline-block;
  padding: 5px 20px;
  margin-top: 7px;
  margin-right: 15px;
  text-decoration: none;
  color: white;
  background-color: transparent; /* Change color as needed */
  border: 1px solid var(--main-border-color);
  border-radius: 20px;
  transition: background-color 0.3s, transform 0.3s, color 0.3s, border-color 0.3s; /* Added transition for border-color */
}

.search-term:hover {
  color: #0a58ca !important;
  border-color: #0a58ca; /* Transition border color to blue on hover */
}




.scrolling-wrapper {
  cursor: grab;
  overflow: hidden;  /* No scrolling on the outer wrapper */
  white-space: nowrap;
}

.scrolling-container {
  overflow-x: scroll;  /* Scrolling on the inner container */
  display: inline-block;  /* Make sure this container fits its content */
  white-space: nowrap;
}

.scrolling-wrapper.active {
  cursor: grabbing;
}

.search-term {
  display: inline-block;
  margin-right: 15px;
}




.anchor-tag {
  color: var(--main-font-color);
  text-decoration: none;
}

.anchor-tag:hover {
  text-decoration: underline;
}













/* old css  */

body {
  animation: fadeInAnimation ease .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}






.nav-link{
  margin-top: 5px;
}


#id_searchv{
  margin-top: 0px !important;
}

#id_searchv2{
  margin-top: 0px !important;
}
#id_search{
  margin-top: 0px !important;
}
#id_searchd{
  margin-top: 0px !important;
}
#id_searchd2{
  margin-top: 0px !important;
}
#creat-comment-input{
  margin-top:3px !important;
}

::-webkit-scrollbar-track {
  background-color: transparent !important; /* or 'rgba(0,0,0,0)' */
}

/* This will style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: rgba(85, 85, 85, 0.175);
  margin-right: 2px !important;
  border-radius: 8px;
}

/* This will set the width of the scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Change the width as per your design */
}
.action-btn:hover {
  transform: scale(1.2);  /* Makes the button slightly larger */
  transition: transform .3s ease, background-color .3s ease, color .3s ease !important;
  cursor: pointer;
  color: #326EFD !important;
  border-radius: 30px;
  border-color: #326EFD;
  background-color: rgba(85, 85, 85, 0.175);
  padding: 1px 10px;


}
.action-label {
  opacity: 0;
  position: absolute;
  bottom: -20px;  /* Adjust this value based on your layout */
  left: 50%;
  transform: translateX(-50%) translateY(-270%);
  background-color: rgb(178, 177, 177);
  padding: 2px 5px;
  font-size: 10px;
  color:   rgb(230, 228, 228);
  display:none !important;

}
.container {
  min-height: 100vh !important;
}
.show {
  transition: opacity 1s ease;
  opacity: 1;
  display:inline !important;
}

.action-btn:hover .action-label {

}
.action-btn:hover p, .action-btn:hover i {
  color: #326EFD !important;

}

.like-btn:hover p, .like-btn:hover i {
  color: #d41818 !important;

}


.action-btn:hover{
  display: block;
}


.action-btn {
  padding: 1px 10px;
  border-radius: 30px;

  transition: color 0.1s ease !important;
}

.action-btn:hover {
  color: #326EFD !important;
}


@media(max-width:1500px){

  #navbar-right-padding{
    padding-right:10px !important;
  }
}
@media(min-width:1500px){
  .container{
    max-width: 100% !important;
    padding-right:12% !important;
    padding-left:12% !important;
  }
}

@media(max-width:1500px){
  .container{
    max-width: 100% !important;
    padding-right:15px !important;
    padding-left:25px !important;
  }
}

@media(min-width:1500px){
  .nav-width{
    max-width: 18% !important;
    width:18% !important;
    min-width:18% !important;
    margin-top:80px !important;

  }
}
@media(max-width:1500px){
  .nav-width{
    max-width: 22.5% !important;
    width:22.5% !important;
  }
}

@media(min-width:1500px){
  .container-top{
    height:80vh !important;
    margin-top: 10vh !important;

  }
}

@media(max-width:1500px){
  .container-top{
    min-height:100vh;
    margin-top: 0vh !important;

  }
}


#no-comments-div{
  margin-right: 0px !important;
  margin-left: 10px !important;
  align-items: center !important;
  padding-top: 25px  !important;
}
.fade-out {
  transition: opacity .9s ease-in-out;
  opacity: 0;
}

#createcomment{
  margin-right:9px !important;
  margin-left:7px !important;
  margin-top:5px !important;
}

#post-timestamp{
  margin-right:15px !important;
  margin-top: -35px !important;
  font-size: 18px !important;
}
#followers-link a{
  color: inherit;
  text-decoration: none;
}
#following-link a{
  color: inherit;
  text-decoration: none;
}

#following-link a:hover {
  text-decoration: underline !important;
}


#followers-link a:hover {
  text-decoration: underline !important;
}


#comment-div-outer{
  border-top: 0px solid #D1D1D1 !important;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  padding-left:3px !important;
}
#createcomment{
  border:0px !important;
  margin-left:5px;
  margin-Top:0px !important;
}


.btn{
    border-radius: 10px;
  
}

#creat-comment-input, #creat-comment-btn{
  border-radius: 10px !important;

}


#creat-content-input, #creat-link-input{
  border-radius: 10px !important;

}



.custom-button-group .btn:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.custom-button-group .btn:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
#create-post-button{
  border-radius: 10px !important;
}

#create-link-button{
  border-radius: 10px !important;
}

#create-file-button{
  border-radius: 10px !important;
}

  #overlay-mobile {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 2;
    cursor: pointer;
  }

  #overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    text-align: center;
  }


#explore-icon {
  transition: font-size 0.1s ease-in-out; /* This will animate the change in font size */
}

#explore-icon:hover {
  font-size: 34px !important; /* This will increase the font size when the user hovers over the element */
}



:root {
  --main-bg-color: #0A0A0A;
  --main-font-color: white;
  --main-border-color: #545454;
}

#gpt-filter{
  border:none !important;
  height:25px !important;
  margin-top:12px !important;

}

#gpt-filter:focus-visible {
  border: none !important;
  outline: none !important;
}
body, textarea, input{
  background-color: var(--main-bg-color) !important;
  border-radius: 10px !important;

}

input, textarea {
  color: var(--main-font-color) !important; 
}

.bg-white, .popover-body{
  background-color: var(--main-bg-color) !important; 

}

li,input[type="text"],#comment-div,hr,p,h1,h2,h3,h4,h5, .form-control, #trending a, .nav-link,.chirp-profile-badge-small, span, #chirp-message,#chirp-conversations{
  color: var(--main-font-color) !important; /* Set color to black */
}

.active{
  color: white !important; /* Set color to black */
}

label,#edit-label,#div_id_email,#div_id_first_name,#div_id_last_name,#div_id_status,#div_id_image{
  color: var(--main-font-color) !important; /* Set color to black */
}





.passwordinput{
  max-width: 15em

}
.emailinput{
  max-width: 15em

}
.textinput{
  max-width: 15em
}

#a_conversation{
  border-top: 0px solid #e8e8e8 !important;
}
#a_conversation:hover{
  border-radius: .3em;
  border-width: 0px;
  background-color: rgba(232, 232, 232, 0.459);
}

.r-navbar {
  background-color: #e8e8e8;
  color:black;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  opacity: 1;
  z-index: 1000;
}

/* Style the links inside the navigation bar */
.r-navbar a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}
#show-slider-icon{
  padding-top: 10px !important;
  padding-left: 15px !important;
}

#show-slider-icon:hover{
  color: #326EFD !important;
}

.fa-ellipsis-h{
  padding-right: 7px;
}
#feed-slider{
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-bottom:12px !important;
  padding-top:5px !important;
}
/* Change the color of links on hover */
.r-navbar a:hover {
  background-color: #326EFD;
  color: white;
}

/* Add a color to the active/current link */
.r-navbar a.active {
  background-color: blue;
  color: white !important;
}

@media(min-width:991px){
    .r-navbar{
        display:none;

    }

}
@media(max-width:950px){
    #trending{
        display:none;

    }
}

@media(max-width:991px){
    #navbar{
        display:none;

    }
}

@media(max-width:1200px){
    #right-bar{
        display:none;

    }
}





input{
  height:36px;
  border-radius: 0.375rem;
  border: 1px solid var(--main-border-color) !important;
  }

#create-post{
  margin-bottom: 5px;
}
.border{
  border-left:solid 0px !important;
  border-right:solid 0px!important;
  border-bottom:solid 0px #CED4DA !important;
  border-top:solid 1px var(--main-border-color) !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.aborder{
  border-left:solid 0px !important;
  border-right:solid 0px!important;
  border-bottom:solid 0px #CED4DA !important;
  border-top:solid 1px #CED4DA !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 5px !important;
}

.bborder{
  border-left:solid 0px !important;
  border-right:solid 0px!important;
  border-bottom:solid 0px #CED4DA !important;
  border-top:solid 1px #CED4DA !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.cborder{
  border-left:solid 0px !important;
  border-right:solid 0px!important;
  border-bottom:solid 0px #CED4DA !important;
  border-top:solid 0px #CED4DA !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
#id_image{
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color) !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color)!important;
  background-color: #fff;
}
body{
    margin-top: 5rem;
}
.nav-link{
  color:black;
  border-radius: 10px !important;
}

.nav-link:hover{
  background-color: rgba(180, 180, 180, 0.459);
}


#id_search:hover{
  background-color: white;
}
#id_search{
  color:black;
  outline:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;
}
#id_search:focus-visible{
  outline:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;
}
#id_searchv:hover{
  background-color: white;
}
#id_searchv{
  color:black;
  outline:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;
}
#id_searchv:focus-visible{
  outline:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;
}
#id_searchd:hover{
  background-color: white;
}
#id_searchd{
  color:black;
  outline:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;
}
#id_searchd:focus-visible{
  outline:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;
}
#id_searchd2{
  color:black;
  outline:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;
}
#id_searchd2:focus-visible{
  outline:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;
}

.form-control{
  outline:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;

}   
.form-control:focus{
  outline:none;
  box-shadow:none;
  border-radius: 0.375rem;
  border:1px solid var(--main-border-color) !important;

}
body{
  margin-top: 2rem;
}


* {
  box-sizing: border-box;
}


.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}


.vjs-control-bar {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1), transparent) !important;
}
.video-js {
  background-color: rgba(99, 99, 99, 0.15) !important;

}

.vjs-mouse-display{
  display: none !important;

}

.vjs-time-tooltip{
  display: none !important;
  
}
.vjs-current-time{
  display: block !important;
}
.vjs-control-bar {
  transform: scale(1, 1.1); /* Adjust the height scaling factor as needed */
  transform-origin: bottom;
}

/* Increased size on hover */
.vjs-volume-panel .vjs-volume-control:hover {
  transform: scale(1, 1.3);
}


.video-wrapper {
  position: relative;
  width: 100%; /* Adjust as necessary */
}

.progress-bar-container {
  position: absolute;
  bottom: 20px !important;
  left: 0;
  width: 50% !important;
  height: 4px !important;
  background-color: rgba(142, 142, 142, 0.5) !important;
  z-index: 100000000; /* Higher than the video player */
  margin-left: 25% !important;
  border-radius: 10px !important;
  transition: transform 0.3s ease; /* Smooth transition for transform */
  transform-origin: center bottom;
}

.progress-bar-container:hover {
  transform: scale(1, 1.6) !important;
}

.progress-bar-container::before,
.progress-bar-container::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 10px; /* Height of the clickable extension */
  background: transparent;
  z-index: -1;
}

.progress-bar-container::before {
  top: -10px; /* Extend area above */
}

.progress-bar-container::after {
  bottom: -10px; /* Extend area below */
}

.progress-bar {
  height: 100%;
  background-color: rgba(0, 102, 255, 0.9) !important; /* Make sure this is visible */
  width: 0%; /* Initial width */
  border-radius: 10px !important;
}


.vjs-load-progress{
  background-color: rgba(142, 142, 142, 0.5) !important;

  border-radius: 25px !important;

}


.vjs-slider-bar{
  background-color: rgba(142, 142, 142, 0.5) !important;
  border-radius: 25px !important;
  
}
.vjs-volume-level{
  background-color: #0D6DFD !important; /* Make sure this is visible */
  border-radius: 25px !important;

}
.vjs-load-progress div {
  background-color: rgba(142, 142, 142, 0.5) !important;

  border-radius: 25px !important;

}
.vjs-load-progress{
  background-color: rgba(142, 142, 142, 0.5) !important;

  border-radius: 25px !important;

}
.vjs-play-progress{
  background-color: #0D6DFD !important; /* Make sure this is visible */

  border-radius: 25px !important;

}

.vjs-volume-bar{
  background-color: rgba(142, 142, 142, 0.5) !important;


}


.thumbnail-slider {
  transition: opacity 0.2s, left 0.2s; /* Smooth the opacity and left position changes */
}